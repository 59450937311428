<template>
  <div style="width:100%">
    <Loading v-show="loading"></Loading>
    <memo-table
      ref="memoTable"
      :query-memo="queryMemo"
      :save-memo="saveMemo"
    >
    </memo-table>
  </div>
</template>
<script>
import memoTable from '@/views/tables/datatable/MemoTable.vue'
import store from '@/store'
import memoboxStoreModule from './memoboxStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import Loading from '@/views/components/load/Loading.vue'
import { getSessionData } from '@core/utils'

const USER_APP_STORE_MODULE_NAME = 'app-memobox'

export default {
  components: {
    memoTable,
    Loading,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
    this.callChildMethod()
  },
  methods: {
    async queryMemo(date) {
      const siteId = String(getSessionData('data').siteId)
      const searchMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
      console.log(searchMonth)
      const rowData = ref([])
      this.loading = true
      await store
        .dispatch('app-memobox/fetchData', {
          params: {
            siteId,
            searchMonth,
          },
        })
        .then(response => {
          rowData.value = response.data.result.data.rowData
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })

      return rowData.value
    },
    saveMemo(array) {
      console.log(array)
      const newArray = []

      // const element = array[0]
      array.forEach(element => {
        const stringDate = element.date.substr(0, element.date.indexOf('('))
        const months = { Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12' }
        const p = stringDate.split('/')
        const dateFormat = `${p[2]}
          -${months[p[1]]}
          -${p[0]}`.replace(/\s+/g, '')
        console.log(dateFormat)
        const obj = {
          date: dateFormat,
          fixedHoliday: element.fixedHoliday,
          memo1: element.memo1,
          memo2: element.memo2,
          memo3: element.memo3,
        }
        newArray.push(obj)
      })
      const memoList = JSON.stringify(newArray)
      const siteId = String(getSessionData('data').siteId)
      store
        .dispatch('app-memobox/saveDate', {
          params: {
            siteId,
            memoList,
          },
        })
        .then(response => {
          console.log(response.data.result.data)
          this.$toasted.success('Your data has been successfully saved')
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },
    callChildMethod() {
      this.$refs.memoTable.helloChild()
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, memoboxStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return { loading: false }
  },
}
</script>
